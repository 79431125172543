export default [
  {
    url: '/',
    name: 'Dashboard',
    slug: 'dashboard',
    icon: 'HomeIcon',
  },
  {
    header: 'Managing',
    icon: 'PackageIcon',
    i18n: 'apps',
    items: [
      // {
      //   url: '/manage/companies',
      //   name: 'Companies',
      //   slug: 'companies',
      //   icon: 'BriefcaseIcon',
      //   i18n: 'Companies',
      // },
      {
        url: '/manage/batches',
        name: 'QR Code',
        slug: 'qrcode',
        icon: 'MaximizeIcon',
        i18n: 'QR Code',
      },
      {
        url: '/manage/customers',
        name: 'Customers',
        slug: 'customers',
        icon: 'UsersIcon',
        i18n: 'Customer',
      },
    ],
  },
  // {
  //   header: 'Report',
  //   i18n: 'report',
  //   items: [
  //     {
  //       url: '/repport/overall',
  //       name: 'Overall',
  //       slug: 'overall',
  //       icon: 'PieChartIcon',
  //       i18n: 'overall',
  //     },
  //     {
  //       url: '/repport/activation',
  //       name: 'Activation',
  //       slug: 'activation',
  //       icon: 'TrendingUpIcon',
  //       i18n: 'activation',
  //     },
  //   ],
  // },
  {
    header: 'Master Data',
    i18n: 'master-data',
    items: [
      // {
      //   url: '/master-data/tags',
      //   name: 'Tags',
      //   slug: 'tags',
      //   icon: 'TagIcon',
      //   i18n: 'Customer',
      // },
      {
        url: '/master-data/brands',
        name: 'Brands',
        slug: 'brands',
        icon: 'HexagonIcon',
        i18n: 'brands',
      },
      {
        url: '/master-data/models',
        name: 'Models',
        slug: 'models',
        icon: 'GridIcon',
        i18n: 'models',
      },
    ],
  },
  // {
  //   header: 'Configs',
  //   i18n: 'configs',
  //   items: [
  //     {
  //       url: '/configs/system',
  //       name: 'System',
  //       slug: 'system',
  //       icon: 'SettingsIcon',
  //       i18n: 'system',
  //     },
  //   ],
  // },
]
